<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认" width="42.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item>
          <div>
            <a-button @click="addTicket(1)" icon="plus-circle">工作人员</a-button>
          </div>
          <div class="table">
            <!-- <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/> -->
            <a-table size="small" :pagination="false" :bordered='false' rowKey='id'
              :columns="columns" :dataSource="list">
                <template slot="work_id" slot-scope="text,record">
                  <div>
                    <a-select style="width:160px" :disabled="record.id>0" allowClear :value="text" @change="e=>handleSelect(e,record)" placeholder="请选择员工" showSearch :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of workers" :key="index" :value="d['work_id']">{{ d['work_name'] }}</a-select-option>
                    </a-select>
                  </div>
                </template>
                <template slot="work_costs" slot-scope="text,record">
                  <a-input-number @change="e=>handleChange(e,record)" :value="text" :min="0" placeholder="请输入"/>
                </template>
                <template slot="work_expect_finish_time" slot-scope="text,record">
                  <div>
                      <a-date-picker format="YYYY-MM-DD HH:mm" @change="e=>handleTime(e,record)" :value="text?moment(text):''" :show-time="{ format: 'HH:mm' }" placeholder="选择时间" />
                  </div>
                </template>
                <template slot="action" slot-scope="text,record,index">
                    <a-popconfirm
                      title="您确认删除该类型"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="deleteItem(record,index)"
                      >
                          <a href="#">删除</a>
                    </a-popconfirm>
                </template>
            </a-table>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const columns = [
  { title: '工作人员', dataIndex: 'work_id', key: 'work_id' , scopedSlots: { customRender: 'work_id' }},
  { title: '预计工时', dataIndex: 'work_costs', key: 'work_costs',scopedSlots: { customRender: 'work_costs' }},
  { title: '预计完成', dataIndex: 'work_expect_finish_time', key: 'work_expect_finish_time',scopedSlots: { customRender: 'work_expect_finish_time' }},
  { title: '操作', key: 'operation', width:'80px',scopedSlots: { customRender: 'action' },align:"right"},
]

import moment from 'moment'
export default {
  name: 'assignModal',
  inject: ['parent'],
  props: {
    smartservice_id: String,
    assign_to:Array
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '工单分配',
      visible: false,
      confirmLoading: false,
      columns,
      list:[],
      listLength:0,
      form: this.$form.createForm(this),
      workers:[],
    }
  },

  async created() {
    this.visible = true
    this.listLength = this.assign_to.length
    this.list = this.assign_to
    this.getWorker()
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getWorker(){
        let res = await this.$store.dispatch('enterpriseWorkOrderWorkerAction', {})
        this.workers = res.data
    },
    async handleOk() {
      let list = [...this.list]
      for(let i=0; i<list.length; i++){
        if(!list[i].work_id){
          this.$message.warning(`第${i + 1}行请选择工作人员!!`)
          return false
        }
        if(!list[i].work_expect_finish_time){
          this.$message.warning(`请填写第${i + 1}行的预计完成时间!!`)
          return false
        }
        if(list[i].id < 0){
          delete list[i].id
        }
      }
      this.confirmLoading = true 
      await this.$store.dispatch('enterpriseWorkOrderAssignAction', {data:{smartservice_id:this.smartservice_id,smartservice_assign:list}})
        .then(res=>{
          this.parent.getList()
          this.confirmLoading = false
          this.$message.success('操作成功~')
          this.handleCancel()
        })
        .catch(err=>{
          this.confirmLoading = false
        })
    },
    handleCancel() {
      this.parent.hideAssignModal(0)
    },
    handleChange(e,record){
      record.work_costs = e
    },
    addTicket(num){
      this.listLength+=1
      let obj = {
        id:`-${this.listLength}`,
        work_id:undefined,
        work_costs:0,
        work_expect_finish_time:''
      }
      this.list.push(obj)
    },
    handleTime(e,record){
      record.work_expect_finish_time = moment(e).format('YYYY-MM-DD HH:mm')
    },
    handleSelect(e,record){
        record.work_id = e
    },
    async deleteItem(item,index) {
      this.list.splice(index,1)
    },
  }
}
</script>
 <style lang="scss" scoped>
  .add-ticket{
    text-align:center;
    border:1px dashed #e5e6e7;
    cursor:pointer;
    margin-top:20px;
    line-height:30px
  }
  .course-margin{
    margin-left: 10px;
  }
 </style>
