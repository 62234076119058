<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>通用设置</a-breadcrumb-item>
            <a-breadcrumb-item>工单系统</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
        <div class="clearfix table-tools">
            <div class="buttons">
            
            </div>
            <div class="search">
            <a-form layout='inline' @submit="searchList">
                <a-form-item>
                <a-tooltip placement="topLeft" >
                    <template slot="title">
                    <span>工单编号</span>
                    </template>
                    <a-input v-model.trim="searchParams.search.fuzzy" placeholder="工单编号/工单名称" style="width: 160px"/>
                </a-tooltip>
                </a-form-item>

                <a-form-item>
                    <a-select style="width:160px" v-model='searchParams.search.wid' @search="handleSearch" allowClear showSearch placeholder="请选择应用" :filterOption="filterOption">
                    <a-select-option v-for="(d, index) of appList" :key="index" :value="d['wid']">{{ d['app_name'] }}</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <template slot="title">
                        <span>时间筛选</span>
                    </template>
                    <a-range-picker
                        style="width: 240px"
                        :ranges="rangesData"
                        :value='rangesValue'
                        :allowClear="true"
                        :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]" 
                        @change="(date, dateString)=>onChange(date, dateString,2)" />
                    </a-tooltip>
                </a-form-item>

                <a-form-item>
                    <a-radio-group :value="searchParams.search.smartservice_level">
                        <a-radio-button @click="handleTypeButChange('60')" value="60">
                            高
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('30')" value="30">
                            中
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('10')" value="10">
                            低
                        </a-radio-button>
                    </a-radio-group>
                </a-form-item>

                <a-form-item>
                    <a-radio-group :value="searchParams.search.smartservice_status">
                        <a-radio-button @click="handleStatusButChange('1')" value="1">
                            待处理
                        </a-radio-button>
                        <a-radio-button @click="handleStatusButChange('10')" value="10">
                            处理中
                        </a-radio-button>
                        <a-radio-button @click="handleStatusButChange('20')" value="20">
                            待反馈
                        </a-radio-button>
                        <a-radio-button @click="handleStatusButChange('100')" value="100">
                            已完成
                        </a-radio-button>
                    </a-radio-group>
                </a-form-item>

                <a-form-item>
                <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
                </a-form-item>
            </a-form>
            </div>
        </div>
        <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="small" :pagination="false" :bordered='false' rowKey="smartservice_id"
            :columns="columns" :dataSource="list" @change="handleChange" :scroll="{ x: 1800 }">
            <template slot="index" slot-scope="text, record , index">
                <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
            <template slot="smartservice_subject" slot-scope="text, record">
                <a slot="action" href="javascript:;" @click="showDetailModal(record)">{{text}}</a>
            </template>

            <template slot="smartservice_assign_to" slot-scope="text,record">
              <div v-for="(it,index) in record.smartservice_assign_to" :key="index">{{it.work_name}} / {{it.work_costs}} / {{it.work_expect_finish_time}}</div>
            </template>

            <template slot="action" slot-scope="text,record">
            <div>
                <a @click="showDetailModal(record)" href="javascript:;">查看</a>
                <a-divider type="vertical"  v-if="record.smartservice_status!='已完成'"/>
                <a @click="showAssignModal(record)" href="javascript:;" v-if="record.smartservice_status!='已完成'">分配</a>
            </div>
            </template>
            </a-table>
        </div>
        <div class="page">
            <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
                </template>
            </a-pagination>
        </div>
        </div>
        <assignModal :assign_to="assign_to" :smartservice_id="smartservice_id" v-if="isAssignModal" />
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '工单编号', dataIndex: 'smartservice_id', key: 'smartservice_id',width:'120px'},
  { title: '应用名称', dataIndex: 'app_name', key: 'app_name',align:"center",width:'120px',sorter:()=>{},ellipsis:true},
  { title: '优先级', dataIndex: 'smartservice_level', key: 'smartservice_level',align:"center",width:'80px',sorter:()=>{}},
  { title: '工单名称', dataIndex: 'smartservice_subject', key: 'smartservice_subject',width:'310px', scopedSlots: { customRender: 'smartservice_subject' } ,ellipsis:true},
  { title: '工单类型', dataIndex: 'smartservice_category', key: 'smartservice_category',align:"center"},
  { title: '工单版本', dataIndex: 'smartservice_version_count', key: 'smartservice_version_count',align:"center",width:'80px'},
  { title: '工单沟通', dataIndex: 'smartservice_conversation_count', key: 'smartservice_conversation_count',align:"center",width:'80px'},
  { title: '提交账号', dataIndex: 'created_by', key: 'created_by' ,align:"center",sorter:()=>{},width:'120px',ellipsis:true},
  { title: '工单状态', dataIndex: 'smartservice_status', key: 'smartservice_status',align:"center",sorter:()=>{},width:'120px'},
  { title: '工时', dataIndex: 'smartservice_costs', key: 'smartservice_costs',align:"center",sorter:()=>{},width:'80px'},
  { title: '工作人员', dataIndex: 'smartservice_assign_to', key: 'smartservice_assign_to',width:'240px',scopedSlots: { customRender: 'smartservice_assign_to' } },  
  { title: '提交时间', dataIndex: 'created_at', key: 'created_at',align:"center",sorter:()=>{}},
  { title: '操作', key: 'operation', width: 100, scopedSlots: { customRender: 'action' },fixed:'right'}
]

    import tableMixins from '@/common/mixins/table'
    import assignModal from './assignModal'
    import ranges from "@/common/mixins/ranges"
    export default {
        name:'list',
        data() {
            return {
                columns,
                rangesValue:[],
                paramsData:[],
                loading: false,
                isAssignModal: false,
                smartservice_id:'',
                searchParams:{
                    search:{
                        smartservice_status:'1'
                    }
                },
                assign_to:[],
                appList:[]
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            assignModal,
        },
        mixins: [ ranges , tableMixins ],
        created () {
            this.getApp()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                this.loading = true
                this.paramsData.start_date && (this.searchParams.search.start_date = this.paramsData.start_date);
                this.paramsData.end_date && (this.searchParams.search.end_date =this.paramsData.end_date);
                await this.$store.dispatch('enterpriseWorkOrderAction', { data: this.searchParams })
                .then(res=>{
                    this.list = res.items
                    this.loading = false
                    this.pageParams = res._meta
                })
            },
            async getApp(val){
                await this.$store.dispatch('enterpriseFilterAppAction', {data:{app_name:val}})
                .then((res)=>{
                    this.appList = res.data
                })
            },
            handleStatusButChange(e){
                if(this.searchParams.search.smartservice_status == e){
                    this.searchParams.search.smartservice_status = undefined
                }else{
                    this.searchParams.search.smartservice_status = e
                }
                this.getList()
            },
            handleTypeButChange(e){
                if(this.searchParams.search.smartservice_level == e){
                    this.searchParams.search.smartservice_level = undefined
                }else{
                    this.searchParams.search.smartservice_level = e
                }
                this.getList()
            },
            showDetailModal(item){
                this.$router.push({path:'/workservice/detail',query:{id:item.smartservice_id}})
            },
            showAssignModal(item){
                this.smartservice_id = item.smartservice_id
                this.assign_to = item.smartservice_assign_to
                this.isAssignModal = true
            },
            hideAssignModal(){
                this.isAssignModal = false
            },
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                    console.log(sorter);
                    if(sorter.order === "ascend"){
                        this.searchParams['sort'] = `${sorter.column.key}`
                    }else{
                        this.searchParams['sort'] = `-${sorter.column.key}`
                    }
                    }else{
                    this.searchParams['sort'] = ''
                    }
                this.getList()
            },
            handleSearch(val){
                this.getApp(val)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>